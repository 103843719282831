<template>
  <div class="home">
    <img
      alt="Vue logo"
      src="../assets/logo.png"
    >
    <h1>{{ $t("WELCOME") }}</h1>
  </div>
</template>

<script>

export default {
  name: 'HomeIndex',
};
</script>
