<template>
  <div>
    <h2>
      Error 404 - Not Found Page
    </h2>
  </div>
</template>

<script>
export default {
  name: 'ErrorPage',
};
</script>
