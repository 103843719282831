<template>
  <div>
    Hello Admin
  </div>
</template>

<script>
export default {
  name: 'AdminIndex',
};
</script>
